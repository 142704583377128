import { getDbTransaction } from '~/utils/IndexedDb/IndexedDb'
import {
  getLastPath,
  getQuery,
  isAudiomackTrackUrl,
  isBandcampUrl,
  isSoundCloudUrl,
  isSpotifyUrl,
  isYoutubeUrl,
  removeQuery,
} from '~/utils/url-validation'

import { DistantApiError } from '~/types/distantApi'

import type { CoreFetch } from '~/types/CoreFetch'
import type {
  AudiomackData,
  BandcampData,
  DistantApiResponse,
  SoundcloudData,
  YoutubeAvailableThumbnailSizes,
  YoutubeData,
} from '~/types/distantApi'
import type { SpotifyTrack } from '~/types/SpotifyApi/index'

export async function getTrackDistantApiData(
  coreFetch: CoreFetch,
  url: string,
): Promise<DistantApiResponse> {
  try {
    const transaction = await getDbTransaction('DistantApi', 2)
    const idbStore = transaction.objectStore('apiCache', 'readonly')
    const result = await idbStore.get(url)

    if (result) return result
  } catch (_) {
    console.warn('IDB fetch failed reverting to default', _)
  }

  const result = await handleRequest(coreFetch, url)

  // Attempt to store in Idb cache
  try {
    const transaction = await getDbTransaction('DistantApi', 2)
    const idbStore = transaction.objectStore('apiCache', 'readwrite')

    await idbStore.put({
      callURL: url,
      ...result,
    })
  } catch (_) {
    console.warn('IDB put failed, skipping cache operation', _)
  }

  return result
}

function getYoutubeId(url: string): string {
  return getQuery(url, 'v') || getLastPath(url) || ''
}

function getSoundCloudId(url: string): string {
  const captureId =
    /soundcloud\.com\/[a-zA-Z0-9-._]*\/?[a-zA-Z0-9-._]*\/?([a-zA-Z0-9-._]*)/
  const id = captureId.exec(url)

  if (id && id[1]) return id[1]

  return ''
}

function handleRequest(coreFetch: CoreFetch, url: string) {
  let id

  if (isYoutubeUrl(url)) {
    id = getYoutubeId(url)
    if (id.length) return handleYtRequest(coreFetch, id)
  }

  if (isSoundCloudUrl(url)) {
    id = getSoundCloudId(url)
    return handleScRequest({ coreFetch, id, url })
  }

  if (isBandcampUrl(url)) return handleBcRequest({ coreFetch, url })

  if (isSpotifyUrl(url)) return handleSpotifyRequest({ coreFetch, url })

  if (isAudiomackTrackUrl(url))
    return handleAudiomackRequest({ coreFetch, url })

  return {
    err: DistantApiError.ERROR,
    thumbnail: null,
    errPlaceholder: '',
    type: 'error',
    embeddedLink: '',
  } as DistantApiResponse
}

async function handleBcRequest({
  coreFetch,
  url,
}: {
  coreFetch: CoreFetch
  url: string
}): Promise<DistantApiResponse> {
  const track: DistantApiResponse = {
    embeddedLink: '',
    type: 'bc' as const,
    errPlaceholder:
      'https://bandcamp.com/EmbeddedPlayer/album=42069/size=large/bgcol=333333/linkcol=ffffff/tracklist=false/artwork=none/transparent=true/ seamless',
    err: DistantApiError.NO_ERROR,
    thumbnail: null,
  }

  const resp = await getBcData(coreFetch, url)
  if (resp === null) {
    track.err = DistantApiError.NO_DATA
  } else {
    track.embeddedLink =
      'https://bandcamp.com/EmbeddedPlayer/size=large/bgcol=333333/linkcol=ffffff/tracklist=false/artwork=none/transparent=true/track=' +
      resp.track_id
    track.thumbnail = resp.thumbnail
  }
  return track
}

async function handleAudiomackRequest({
  coreFetch,
  url,
}: {
  coreFetch: CoreFetch
  url: string
}): Promise<DistantApiResponse> {
  const track: DistantApiResponse = {
    embeddedLink: '',
    type: 'audiomack' as const,
    errPlaceholder:
      'https://groover.co/media/partners/audiomack_orange_logo.png seamless',
    err: DistantApiError.NO_ERROR,
    thumbnail: null,
  }

  const resp = await getAudiomackData(coreFetch, url)
  if (resp === null) track.err = DistantApiError.NO_DATA
  else track.thumbnail = resp.thumbnail

  return track
}

async function handleScRequest({
  coreFetch,
  id,
  url,
}: {
  coreFetch: CoreFetch
  id: string | boolean
  url: string
}): Promise<DistantApiResponse> {
  const secretId = typeof id === 'string' ? id : ''
  const track: DistantApiResponse = {
    embeddedLink: '',
    type: 'sc',
    errPlaceholder:
      'https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/337177738',
    err: DistantApiError.NO_ERROR,
    thumbnail: null,
  }
  const resp = await getScData(coreFetch, removeQuery(url))

  if (resp === null) {
    track.err = DistantApiError.NO_DATA
  } else {
    track.err = DistantApiError.NO_ERROR
    track.embeddedLink = `https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/${resp.id}&color=%23000&auto_play=true&hide_related=true&show_comments=false&show_user=false&show_reposts=false&show_teaser=false&visual=true&sharing=false&show_artwork=true`
    track.thumbnail = resp.img
    if (secretId.length > 0 || resp?.track_slug)
      track.embeddedLink += `&secret_token=${secretId || resp?.track_slug}`
  }
  return track
}

async function handleYtRequest(
  coreFetch: CoreFetch,
  id: string,
): Promise<DistantApiResponse> {
  const track: DistantApiResponse = {
    embeddedLink: '',
    type: 'yt',
    errPlaceholder: 'lmaourlinkbrokefam',
    err: DistantApiError.NO_ERROR,
    thumbnail: null,
  }
  const resp = await getYtData(coreFetch, id)

  if (resp === null) {
    track.err = DistantApiError.NO_DATA
  } else {
    track.embeddedLink = id
    if (resp.items[0]) {
      const item = resp.items[0]

      if (item.snippet && item.snippet.thumbnails) {
        const priority: YoutubeAvailableThumbnailSizes[] = [
          'high',
          'standard',
          'default',
          'medium',
        ]
        const thumbnailKeys = Object.keys(item.snippet.thumbnails)
        const selectedKey = priority.find((f) => thumbnailKeys.includes(f))

        if (selectedKey)
          track.thumbnail = item.snippet.thumbnails[selectedKey].url
      }
      if (item.status && item.status.embeddable === false)
        track.err = DistantApiError.NOT_EMBEDDABLE
    } else {
      track.err = DistantApiError.EMBEDDABLE
    }
  }
  return track
}

async function handleSpotifyRequest({
  coreFetch,
  url,
}: {
  coreFetch: CoreFetch
  url: string
}): Promise<DistantApiResponse> {
  const resp = await getSpData(coreFetch, url).catch(() => null)

  return resp
    ? {
        embeddedLink: resp.preview_url,
        type: 'sp',
        errPlaceholder: '',
        err: DistantApiError.NO_ERROR,
        thumbnail: resp.album?.images?.[0].url || null,
      }
    : {
        embeddedLink: '',
        type: 'sp',
        errPlaceholder: '',
        err: DistantApiError.NO_DATA,
        thumbnail: null,
      }
}

export function getYtData(
  coreFetch: CoreFetch,
  id: string,
): Promise<YoutubeData | null> {
  return new Promise((resolve) => {
    coreFetch
      .$post('/distantapi/yt/getdata/', {
        id,
      })
      .then((resp) => {
        resolve(resp)
      })
      .catch(() => {
        resolve(null)
      })
    if (!id?.length) throw new Error('Missing id when probing youtube')
  })
}

export function getScData(
  coreFetch: CoreFetch,
  url: string,
): Promise<SoundcloudData | null> {
  return new Promise((resolve) => {
    coreFetch
      .$post('/distantapi/sc/getdata/', {
        url,
      })
      .then((resp) => {
        resolve(resp)
      })
      .catch(() => {
        resolve(null)
      })
  })
}

export function getAudiomackData(
  coreFetch: CoreFetch,
  url: string,
): Promise<AudiomackData | null> {
  return new Promise((resolve) => {
    coreFetch
      .$post('/distantapi/audiomack/getdata/', {
        url,
      })
      .then((resp) => {
        resolve(resp)
      })
      .catch(() => {
        resolve(null)
      })
  })
}

function getBcData(
  coreFetch: CoreFetch,
  url: string,
): Promise<null | BandcampData> {
  return new Promise((resolve) => {
    coreFetch
      .$post('/distantapi/bc/getdata/', {
        url,
      })
      .then((resp) => {
        resolve(resp)
      })
      .catch(() => {
        resolve(null)
      })
  })
}

function getSpData(
  coreFetch: CoreFetch,
  url: string,
): Promise<SpotifyTrack | null> {
  return coreFetch
    .$post<SpotifyTrack>('/distantapi/spotify/getdata/', {
      url,
    })
    .catch(() => null)
}
