<script setup lang="ts">
import Accordion from '~/components/dato/Accordion.vue'
import Bento from '~/components/dato/Bento.vue'
import CardCluster from '~/components/dato/CardCluster.vue'
import Charts from '~/components/dato/Charts/Charts.vue'
import CtasSection from '~/components/dato/CtasSection.vue'
import CuratorCarousel from '~/components/dato/CuratorCarousel/CuratorCarousel.vue'
import Divider from '~/components/dato/Divider.vue'
import FeaturedUser from '~/components/dato/FeaturedUser/FeaturedUser.vue'
import FullWidthImage from '~/components/dato/FullWidthImage.vue'
import FullWidthVideo from '~/components/dato/FullWidthVideo.vue'
import Hero from '~/components/dato/Hero.vue'
import IconList from '~/components/dato/IconList.vue'
import Iframe from '~/components/dato/Iframe.vue'
import ImageSlideshow from '~/components/dato/ImageSlideshow.vue'
import LogoCarousel from '~/components/dato/LogoCarousel.vue'
import SeoFooter from '~/components/dato/SeoFooter.vue'
import Testimonial from '~/components/dato/Testimonial.vue'
import TextImage from '~/components/dato/TextImage/TextImage.vue'
import TitleSection from '~/components/dato/TitleSection.vue'

import type { BoxFragment, CommonContentRecord } from '~/graphql/generated'

type Props = BoxFragment & { commonContent?: CommonContentRecord }

const props = withDefaults(defineProps<Props>(), {
  backgroundColor: '',
  sections: () => [],
  removeVerticalPaddings: false,
  commonContent: undefined,
})

const bgClass = computed(() => {
  switch (props.backgroundColor) {
    case '#FBBA66': // down
    case '#EAEFF8': // old blue becomes down
      return 'tw-bg-down'
    case '#2B2B2B': // charcoal
    case '#131313': // old black becomes charcoal
      return 'tw-bg-fill-alt'
    case '#EEEEEE': // light grey
      return 'tw-bg-fill-discrete3'
    default: // lin
      return 'tw-bg-fill'
  }
})

function getComponentAndBindFromTypeName(
  section: (typeof props.sections)[number],
) {
  switch (section.__typename) {
    case 'AccordionRecord':
      return markRaw(Accordion)
    case 'BentoRecord':
      return markRaw(Bento)
    case 'ChartRecord':
      return markRaw(Charts)
    case 'CtasSectionRecord':
      return markRaw(CtasSection)
    case 'CuratorCarouselRecord':
      return markRaw(CuratorCarousel)
    case 'DividerRecord':
      return markRaw(Divider)
    case 'HeroRecord':
      return markRaw(Hero)
    case 'IconListRecord':
      return markRaw(IconList)
    case 'ImageSlideshowRecord':
      return markRaw(ImageSlideshow)
    case 'LogoCarouselRecord':
      return markRaw(LogoCarousel)
    case 'FeaturedUserRecord':
      return markRaw(FeaturedUser)
    case 'TestimonialRecord':
      return markRaw(Testimonial)
    case 'TextImageRecord':
      return markRaw(TextImage)
    case 'TitleSectionRecord':
      return markRaw(TitleSection)
    case 'CardClusterRecord':
      return markRaw(CardCluster)
    case 'FullWidthImageRecord':
      return markRaw(FullWidthImage)
    case 'FullWidthVideoRecord':
      return markRaw(FullWidthVideo)
    case 'IframeRecord':
      return markRaw(Iframe)
    case 'SeoFooterRecord':
      return markRaw(SeoFooter)
    default:
      return 'div'
  }
}
</script>

<template>
  <div
    class="tw-flex tw-flex-col tw-gap-3xl md:tw-gap-5xl lg:tw-gap-14"
    :class="[
      bgClass,
      { 'tw-py-4xl md:tw-py-14 lg:tw-py-7xl': !removeVerticalPaddings },
    ]"
  >
    <div
      v-for="(section, index) in sections.filter((section) => 'id' in section)"
      :key="section.id ?? -index"
      class=""
    >
      <component
        v-bind="{ ...section, commonContent }"
        :is="getComponentAndBindFromTypeName(section)"
      />
    </div>
  </div>
</template>

<style scoped lang="scss">
// "alt" coloring schema
.tw-bg-fill-alt:deep() {
  @apply tw-text-inverse;

  /** --- Specific overrides --- */

  // structured text elements
  .prose-base
    :is(
      :where(h1, h2, h3, h4, h5, h6, p, a):not(
          :where([class~='tw-not-prose'], [class~='tw-not-prose'] *)
        )
    ) {
    @apply tw-text-inverse;

    .currency-swapper {
      @apply tw-text-down;

      > span > span {
        @apply tw-text-down;
      }
    }
  }

  .featuredUserCard {
    @apply tw-bg-fill;

    .tw-text-link-inverse {
      @apply tw-text-primary;
    }

    :last-child {
      @apply tw-text-default-color;
    }
  }

  .bentoItem.tw-bg-fill-alt {
    @apply tw-bg-fill tw-text-default-color;
  }

  // curator carousel
  .selectionPill {
    @apply tw-text-inverse;

    &.tw-border-none {
      @apply tw-bg-default tw-text-default-color;
    }
  }

  .logoCarousel {
    h2 {
      @apply tw-text-discrete3;
    }

    .tw-bg-gradient-to-r:first-of-type {
      @apply tw-from-charcoal tw-to-transparent;
    }

    .tw-bg-gradient-to-r:nth-of-type(2) {
      @apply tw-to-charcoal tw-from-transparent;
    }
  }

  .iconList {
    div > div {
      @apply tw-bg-fill tw-text-default-color;
    }

    .tw-text-icon-discrete3 {
      @apply tw-text-icon-discrete;
    }

    @screen md {
      @apply tw-bg-fill;
    }
  }

  .cardCluster .cardClusterLink {
    // not sure why but "tw-text-link-inverse" is not working here
    @apply tw-text-[#ff7061] focus-visible:tw-outline-focus-inverse;
  }

  .accordion .faqAccordionCard {
    @apply tw-bg-fill;
  }
}

/** --- Overrides for other bg colors --- */

.tw-bg-down:deep() {
  .accordion .tw-bg-default {
    @apply tw-bg-[#FFDAAC];

    .prose-accordion
      :where(ul > li):not(
        :where([class~='tw-not-prose'], [class~='tw-not-prose'] *)
      )::marker {
      @apply tw-text-default-color;
    }
  }

  .logoCarousel {
    h2 {
      @apply tw-text-default-color;
    }

    .tw-bg-gradient-to-r:first-of-type {
      @apply tw-from-down tw-to-transparent;
    }

    .tw-bg-gradient-to-r:nth-of-type(2) {
      @apply tw-from-transparent tw-to-down;
    }
  }

  .testimonials-container {
    @apply tw-text-default-color;

    .testimonial-panel {
      @apply tw-bg-[#FFDAAC];
    }

    .artist-name {
      @apply tw-text-link;
    }

    .caret-link {
      @apply tw-text-default-color;
    }
  }
}

.tw-bg-fill-discrete3:deep() {
  .accordion .tw-bg-default {
    @apply tw-bg-fill;
  }

  .logoCarousel {
    .tw-bg-gradient-to-r:first-of-type {
      @apply tw-from-discrete3 tw-to-transparent;
    }

    .tw-bg-gradient-to-r:nth-of-type(2) {
      @apply tw-to-discrete3 tw-from-transparent;
    }
  }
}
</style>
