<script setup lang="ts">
import { storeToRefs } from 'pinia'

import BandSelect from './band/bandSelect.vue'

import NavLink from '~/components/nav/NavLink.vue'

import { useUserStore } from '~/stores/user'

const { IS_BAND, IS_AGENCY, IS_LOGGED_IN } = storeToRefs(useUserStore())
const { grooviz: GROOVIZ_AMOUNT } = storeToRefs(useUserStore())
</script>

<template>
  <div v-if="IS_BAND" class="navContextMenuArtistWrapper">
    <div v-if="IS_LOGGED_IN && IS_AGENCY" class="paddedWrapper">
      <div class="borderedWrapper">
        <BandSelect class="fitBandSelect" />
      </div>
    </div>
    <div class="infos" data-test-id="profileDropdownListsCTA">
      <span class="title tw-cursor-default !tw-text-base">{{
        $t('band.RPMenu.account')
      }}</span>
    </div>
    <NavLink class="navBaseElem paddedElem" to="/agency/favorites/">{{
      $t('layout.menu.menu00')
    }}</NavLink>
    <NavLink
      class="navBaseElem paddedElem"
      data-test-id="profileDropdownWalletCTA"
      to="/band/shop/"
    >
      <span class="text">{{ $t('layout.menu.menu01') }}</span>
      <span class="fairRight">
        <span class="balance">{{ GROOVIZ_AMOUNT }}</span>
        <i alt="Grooviz" class="icon-grooviz tw-ml-1" />
      </span>
    </NavLink>
  </div>
</template>

<style scoped src="~/components/nav/loggedIn/navBaseElem.css" />
<style scoped>
.navContextMenuArtistWrapper {
  position: relative;
  width: 100%;
}

.borderedWrapper {
  display: flex;
  flex-direction: column;
  padding: 20px 0px;
  border-top: 1px solid var(--color-light-grey);
  border-bottom: 1px solid var(--color-light-grey);
}

.paddedWrapper {
  padding: 0px 20px;
  margin: 20px 0px 0px 0px;
}

.infos {
  display: flex;
  flex-direction: column;
}

.title {
  font-weight: 500;
  font-size: var(--font-size-title-nano);
  margin-top: 20px;
  margin-left: 20px;
  margin-bottom: 12px;
}

.text {
  color: var(--color-black);
  font-size: var(--font-size-small);
  font-weight: 500;
}

.subtitle {
  font-size: var(--font-size-small);
  color: var(--color-storm-grey);
}

.euclid {
  font-weight: 700;
  margin-left: 4px;
}

.navBaseElem.balance {
  font-size: var(--font-size-eleven);
  color: var(--color-darker-grey);
  font-weight: 500;
}

.grooviesIcon {
  margin-left: 4px;
  width: 12px;
  height: 12px;
}

@media screen and (min-width: 768px) {
  .title {
    font-size: var(--font-size-big);
  }
}
</style>
