import payload_plugin_ljKcX9H4Cq from "/root/front/node_modules/.pnpm/@pinia+nuxt@0.9.0_magicast@0.3.5_pinia@2.3.0_typescript@5.7.3_vue@3.5.13_typescript@5.7.3___rollup@4.30.1/node_modules/@pinia/nuxt/dist/runtime/payload-plugin.js";
import payloadToJson_f85A5nYSY0 from "/root/front/plugins/payloadToJson.ts";
import revive_payload_client_dsxz6lUYOH from "/root/front/node_modules/.pnpm/nuxt@3.15.1_@parcel+watcher@2.5.0_@types+node@20.17.13_db0@0.2.1_eslint@8.57.1_ioredis@5.4.2__hxlwfz36mdvoaji7kuerms6t2e/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_orXz77Ocic from "/root/front/node_modules/.pnpm/nuxt@3.15.1_@parcel+watcher@2.5.0_@types+node@20.17.13_db0@0.2.1_eslint@8.57.1_ioredis@5.4.2__hxlwfz36mdvoaji7kuerms6t2e/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_wcvBwxP8vo from "/root/front/node_modules/.pnpm/nuxt@3.15.1_@parcel+watcher@2.5.0_@types+node@20.17.13_db0@0.2.1_eslint@8.57.1_ioredis@5.4.2__hxlwfz36mdvoaji7kuerms6t2e/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_JA4kBKadmS from "/root/front/node_modules/.pnpm/nuxt-site-config@3.0.6_magicast@0.3.5_rollup@4.30.1_vite@5.4.11_@types+node@20.17.13_sass@1.8_hiqlj5ftzogiyk3dlteaz355xm/node_modules/nuxt-site-config/dist/runtime/app/plugins/0.siteConfig.js";
import payload_client_0xrXNHnfA3 from "/root/front/node_modules/.pnpm/nuxt@3.15.1_@parcel+watcher@2.5.0_@types+node@20.17.13_db0@0.2.1_eslint@8.57.1_ioredis@5.4.2__hxlwfz36mdvoaji7kuerms6t2e/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_Do2fVhAmrC from "/root/front/node_modules/.pnpm/nuxt@3.15.1_@parcel+watcher@2.5.0_@types+node@20.17.13_db0@0.2.1_eslint@8.57.1_ioredis@5.4.2__hxlwfz36mdvoaji7kuerms6t2e/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import chunk_reload_client_TlTIZOXgYh from "/root/front/node_modules/.pnpm/nuxt@3.15.1_@parcel+watcher@2.5.0_@types+node@20.17.13_db0@0.2.1_eslint@8.57.1_ioredis@5.4.2__hxlwfz36mdvoaji7kuerms6t2e/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_50BRjPjiLj from "/root/front/node_modules/.pnpm/@pinia+nuxt@0.9.0_magicast@0.3.5_pinia@2.3.0_typescript@5.7.3_vue@3.5.13_typescript@5.7.3___rollup@4.30.1/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/root/front/.nuxt/components.plugin.mjs";
import prefetch_client_jA1D35k5Tl from "/root/front/node_modules/.pnpm/nuxt@3.15.1_@parcel+watcher@2.5.0_@types+node@20.17.13_db0@0.2.1_eslint@8.57.1_ioredis@5.4.2__hxlwfz36mdvoaji7kuerms6t2e/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import switch_locale_path_ssr_sOXyA866ut from "/root/front/node_modules/.pnpm/@nuxtjs+i18n@8.5.6_magicast@0.3.5_rollup@4.30.1_vue@3.5.13_typescript@5.7.3_/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.js";
import i18n_pe2eoexaYA from "/root/front/node_modules/.pnpm/@nuxtjs+i18n@8.5.6_magicast@0.3.5_rollup@4.30.1_vue@3.5.13_typescript@5.7.3_/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.js";
import plugin_wy0B721ODc from "/root/front/node_modules/.pnpm/@nuxtjs+device@3.2.4/node_modules/@nuxtjs/device/dist/runtime/plugin.js";
import polyfills_client_xo6BY28vLt from "/root/front/plugins/polyfills.client.ts";
import globalNuxtLink_8h7uhS7dNV from "/root/front/plugins/globalNuxtLink.ts";
import sentry_3AyO8nEfhE from "/root/front/plugins/sentry.ts";
import pinia_48xmdi2HHl from "/root/front/plugins/pinia.ts";
import globalMixin_0vW6V1IUxN from "/root/front/plugins/globalMixin.ts";
import initCsrf_GudLqtAxMz from "/root/front/plugins/initCsrf.ts";
import segment_xRnKTSkpmU from "/root/front/plugins/segment.ts";
import segmentPageTracking_CDhc5tQIUy from "/root/front/plugins/segmentPageTracking.ts";
import closeCookiebotDialog_wHIJHWT4P3 from "/root/front/plugins/closeCookiebotDialog.ts";
import growthBook_T0cxi0ahK4 from "/root/front/plugins/growthBook.ts";
import stripe_6FZUdDHpIZ from "/root/front/plugins/stripe.ts";
import vueGtag_qdVBVzWkdt from "/root/front/plugins/vueGtag.ts";
import preview_6oGpsaen2C from "/root/front/plugins/preview.ts";
import customerIO_cJd4DriT22 from "/root/front/plugins/customerIO.ts";
export default [
  payload_plugin_ljKcX9H4Cq,
  payloadToJson_f85A5nYSY0,
  revive_payload_client_dsxz6lUYOH,
  unhead_orXz77Ocic,
  router_wcvBwxP8vo,
  _0_siteConfig_JA4kBKadmS,
  payload_client_0xrXNHnfA3,
  navigation_repaint_client_Do2fVhAmrC,
  chunk_reload_client_TlTIZOXgYh,
  plugin_vue3_50BRjPjiLj,
  components_plugin_KR1HBZs4kY,
  prefetch_client_jA1D35k5Tl,
  switch_locale_path_ssr_sOXyA866ut,
  i18n_pe2eoexaYA,
  plugin_wy0B721ODc,
  polyfills_client_xo6BY28vLt,
  globalNuxtLink_8h7uhS7dNV,
  sentry_3AyO8nEfhE,
  pinia_48xmdi2HHl,
  globalMixin_0vW6V1IUxN,
  initCsrf_GudLqtAxMz,
  segment_xRnKTSkpmU,
  segmentPageTracking_CDhc5tQIUy,
  closeCookiebotDialog_wHIJHWT4P3,
  growthBook_T0cxi0ahK4,
  stripe_6FZUdDHpIZ,
  vueGtag_qdVBVzWkdt,
  preview_6oGpsaen2C,
  customerIO_cJd4DriT22
]