<script setup lang="ts">
import { storeToRefs } from 'pinia'

import NavLink from '~/components/nav/NavLink.vue'
import VText from '~/components/ui/VText.vue'

import { useGetMediaUrl } from '~/composables/useGetMediaUrl'

import { useUserStore } from '~/stores/user'

const { getMediaUrl } = useGetMediaUrl()

type Emits = {
  close: []
}

const emit = defineEmits<Emits>()

const { grooviz: GROOVIZ } = storeToRefs(useUserStore())
</script>

<template>
  <div class="tw-grid tw-grid-cols-1">
    <NavLink
      class="hoverText tw-flex tw-items-center tw-justify-start tw-gap-2"
      to="/influencer/cashout/"
      @click="emit('close')"
    >
      <span> {{ $t('layout.navigation08', GROOVIZ) }} </span
      ><i
        class="icon-grooviz !tw-text-base tw-text-icon-discrete2"
        aria-label="Grooviz"
      />
    </NavLink>
    <NavLink
      class="hoverText"
      to="/influencer/dashboard/"
      @click="emit('close')"
      >{{ $t('layout.navigation03') }}</NavLink
    >
  </div>
</template>
